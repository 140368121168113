import {useNavigate} from "react-router-dom";
import {useRef, useState} from "react";
import * as XLSX from "xlsx";
import Footer from "../../component/footer";
import {Button, CircularProgress, LinearProgress, Stack} from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {useSnackbar} from "notistack";
import SearchIcon from "@mui/icons-material/Search";
import { format } from 'date-fns';
import {FileDownload} from "@mui/icons-material";


const maksimumKisiSayisi = 30;

const Cekilis = () => {

    const navigate = useNavigate();
    const fileInputRef = useRef(null);


    const [secilenKisiler, setSecilenKisiler] = useState([]);
    const [secilenlerinYorumlari, setSecilenlerinYorumlari] = useState([]);
    const [counter, setCounter] = useState(0);
    const [data, setData] = useState(null);
    const [satirSayisi, setSatirSayisi] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);

    const {enqueueSnackbar} = useSnackbar();


    const handleLogout = async () => {
        try {
            localStorage.removeItem("jwtToken");
            navigate('/');
        } catch (err) {
            console.error(err);
            enqueueSnackbar("Hata !", {
                variant: "error",
                autoHideDuration: process.env.REACT_APP_NOTIFICATION_TIMEOUT,
                anchorOrigin: {vertical: "bottom", horizontal: "right"}
            });        }
    };

    const handleFileChange = (event) => {
        setLoadingFile(true);
        const file = event.target.files[0];


        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const workbook = XLSX.read(e.target.result, {type: 'binary'});
                const sheetName = workbook.SheetNames[0];
                const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

                // Set the data in the state
                setData(excelData);
                setSatirSayisi(excelData.length);
                //setInputValue(excelData.length);

                enqueueSnackbar("Dosya okuma işlemi tamamlandı !", {
                    variant: "info",
                    autoHideDuration: process.env.REACT_APP_NOTIFICATION_TIMEOUT,
                    anchorOrigin: {vertical: "bottom", horizontal: "right"}
                });
                setLoadingFile(false);
            };

            reader.readAsBinaryString(file);
        }
    };

    // 1 ile toplam satır sayısı arasında rastgele bir satırı seçerek "Çekilişte Seçilenler" tablosuna
    // ekleyen modül.
    const generateRandomValue = () => {
        // 1 ile toplam satır sayısı arasında, üretilme olasılığı eşit olan, rastgele bir değer seçilir.
        const randomNum = (Math.floor(Math.random() * satirSayisi) + 1);
        if (counter < maksimumKisiSayisi) {
            if (satirSayisi != null) {
                setLoading(true)

                setTimeout(() => {
                    setCounter(counter + 1);
                    setLoading(false)
                    const selectedRow = data[randomNum];
                    console.log(JSON.stringify(selectedRow));

                    // Rastgele seçilen satırdaki talihli "Çekilişte Seçilenler" tablosuna eklenir.
                    setSecilenKisiler((prevSecilenKisiler) => [...prevSecilenKisiler, selectedRow]);
                }, 2000);
            } else {
                enqueueSnackbar("Yorumların olduğu dosyayı seçiniz !", {
                    variant: "warning",
                    autoHideDuration: process.env.REACT_APP_NOTIFICATION_TIMEOUT,
                    anchorOrigin: {vertical: "bottom", horizontal: "right"}
                });
            }
        }
    }


    const showComments = (x) => {
        const comments = data.filter(item => item["Username"] === x)
        //alert(JSON.stringify(comments))
        setSecilenlerinYorumlari(comments);
    }

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const exportToExcel = () => {
        const currentDateTime=new Date();

        const ws = XLSX.utils.json_to_sheet(secilenKisiler);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'cekilis-'+format(currentDateTime, 'yyyy-MM-dd HH:mm:ss')+ '.xlsx');
    };

    return (


        <div className="flex min-h-screen w-screen flex-col bg-slate-400  break-all">
            <div className="flex h-32 w-screen bg-white justify-center">

{/*
                 <h1 className={"text-black text-6xl font-bold mt-6"}>Çekiliş Uygulaması</h1>
*/}
                <div className="max-w-3xl mx-auto text-center mt-4">
                    <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-2 pb-4 relative">
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">Çekiliş Uygulaması</span>
                        <span
                            className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-purple-500 to-pink-500"></span>
                    </h1>
                    <p className="text-lg text-bold text-red-800 mb-8">Dudullu.com</p>
                </div>



            </div>

            <div className={"flex h-auto w-screen flex-row grow bg-red-600 "}>

                {/*//main 1*/}

                <div className={"flex flex-col w-1/5  p-2 bg-red-500  "}>


                    <div className={" text-amber-50"}>
                        {/* <div className={" m-5 break-all pr-4 overflow-hidden"}>
                                <input style={{display: 'none'}} type="file" onChange={handleFileChange}/>
                            </div>*/}
                        <div className={" m-5 break-all pr-4 font-bold"}>
                            <input
                                type="file"
                                id="fileInput"
                                ref={fileInputRef}
                                style={{display: 'none'}}
                                onChange={handleFileChange}
                            />

                            <Button variant={"contained"}
                                    onClick={handleButtonClick}
                                    endIcon={<FileUploadIcon/>}
                                    sx={{textTransform: "none", backgroundColor: '#000000'}}
                            >
                                Dosyayı Yükle
                            </Button>
                        </div>

                        <div>
                            {loadingFile &&
                                <Stack  sx={{width: '100%', color: 'grey.500'}}  spacing={2}>
                                    <LinearProgress
                                        color="primary"
                                    />
                                    {/*<CircularProgress color="info" />*/}

                                </Stack>
                            }
                            {data && (

                                <div className={"w-1/3 h-96 overflow-scroll w-full "}>
                                    <table className={" w-full text-sm "}>
                                        <tr className={"bg-gray-800"}>

                                            <td className={"border-2 bg-gray-800 w-9 p-1"}> No</td>
                                            <td className={"border-2 w-24 p-2"}> Kullanıcı Adı</td>
                                            <td className={"border-2 p-2"}> Yorumu</td>

                                        </tr>
                                        {data.map((item, index) => (

                                            <tr key={index}>
                                                < td
                                                    className={"border-2 bg-gray-800 w-6 p-1 "}> {index + 1}  </td>
                                                < td className={"border-2 p-1 "}> {item.Username}  </td>
                                                < td className={"border-2 p-1 "}> {item?.["Comment Text"]}  </td>
                                            </tr>


                                        ))}
                                    </table>
                                </div>
                            )}
                        </div>

                    </div>
                </div>


                {/*main 2*/}
                <div className={"flex flex-col justify-start w-1/5 p-11 "}>
                    {satirSayisi !== null && satirSayisi > 0 &&
                        <>

                            <div className={"mt-6"}>
                                <h3 className={"font-bold text-white"}>
                                    Yorum Sayısı: <span className={"text-2xl text-black"}> {satirSayisi} </span>
                                </h3>

                            </div>

                            <div className={"text-white font-bold mt-4 "}>


                                <Button variant={"contained"}
                                        onClick={generateRandomValue}
                                    //endIcon={<FileUploadIcon/>}
                                        sx={{textTransform: "none", backgroundColor: '#000000'}}
                                >
                                    Sıradaki Talihliyi Belirle
                                </Button>
                            </div>
                            <div className={"mt-6"}>
                                {loading &&
                                    <Stack sx={{width: '100%', color: 'grey.500'}} spacing={2}>
                                        <LinearProgress color="info"/>
                                        <LinearProgress color="secondary"/>
                                        <LinearProgress color="success"/>
                                        <LinearProgress color="info"/>
                                    </Stack>
                                    // <CircularProgress color="info" />
                                }

                            </div>
                        </>


                    }


                </div>


                {/*main 3*/}
                <div className={"flex flex-col justify-start w-2/5 p-3 bg-red-500 "}>

                    {satirSayisi !== null && satirSayisi > 0 &&
                        <>
                            <div className={"text-white font-bold mt-6 ml-20"}>
                                Çekilişte Seçilenler
                            </div>
                            {secilenKisiler.length > 0 &&
                                <table className={"w-full h-16 text-white text-sm mt-4"}>
                                    <tr className={"bg-gray-800"}>

                                        <td className={"border-2 bg-gray-800 w-9 p-1"}> No</td>
                                        <td className={"border-2 w-24 p-2"}> Kullanıcı Adı</td>
                                        <td className={"border-2 p-2"}> Yorumu</td>
                                        <td className={"border-2 w-20 p-2"}> Yorumlar</td>
                                    </tr>
                                    {secilenKisiler.map((item, index) => (
                                        <tr key={index}>
                                            < td className={"border-2 bg-gray-800 w-6 p-1 "}>
                                                {index + 1}
                                                {/*<img src={item?.["Avatar URL"]}/>*/}
                                            </td>
                                            < td className={"border-2 p-1 "}> {item.Username}  </td>
                                            < td className={"border-2 p-1 "}> {item?.["Comment Text"]}  </td>
                                            <td className={"border-2 p-3"}>
                                                <Button
                                                    sx={{
                                                        color: 'black', padding: 1,
                                                        border: '1px solid rgba(133,133,133,0.6)',
                                                        borderRadius: '4px',

                                                    }}
                                                    disableElevation
                                                    onClick={() => {
                                                        showComments(item.Username)
                                                    }}

                                                > <SearchIcon
                                                />
                                                </Button>
                                            </td>
                                        </tr>


                                    ))}
                                </table>
                            }

                            <Button variant={"contained"}
                                    onClick={exportToExcel}
                                    endIcon={<FileDownload/>}
                                    sx={{textTransform: "none",

                                        backgroundColor: '#000000',
                                        marginTop: '10px'
                                        /*'&:hover': {
                                            backgroundColor: '#aaaaaa', // You can adjust the darkness level
                                        }*/

                                    }}
                            >

                               Seçilenleri Kaydet
                            </Button>
                        </>
                    }

                </div>
                <div className={"w-1/5  bg-red-600  p-3 "}>
                    {satirSayisi !== null && satirSayisi > 0 &&

                        <div>
                            {secilenlerinYorumlari.length > 0 &&
                                <>
                                    <div className={"mt-6 ml-20"}>
                                        <h3 className={"font-bold text-white"}>
                                            Yorum Sayısı: <span
                                            className={"text-2xl text-black"}> {secilenlerinYorumlari.length} </span>
                                        </h3>

                                    </div>

                                    <div className={"w-1/3 h-96 overflow-scroll w-full mt-2 "}>
                                        <table className={" w-full text-sm text-white "}>
                                            <tr className={"bg-gray-800"}>

                                                <td className={"border-2 bg-gray-800 w-9 p-1"}> No</td>
                                                <td className={"border-2 w-24 p-2"}> Kullanıcı Adı</td>
                                                <td className={"border-2 p-2"}> Yorumu</td>

                                            </tr>
                                            {secilenlerinYorumlari.map((item, index) => (

                                                <tr key={index}>
                                                    < td
                                                        className={"border-2 bg-gray-800 w-6 p-1 "}> {index + 1}  </td>
                                                    < td className={"border-2 p-1 "}> {item.Username}  </td>
                                                    < td className={"border-2 p-1 "}> {item?.["Comment Text"]}  </td>
                                                </tr>


                                            ))}
                                        </table>
                                    </div>
                                </>
                            }
                        </div>


                    }

                </div>

            </div>
            <div className="flex h-12 w-screen bg-white">
                {/*<button onClick={handleLogout}>Çıkış</button>*/}
                <Footer/>
            </div>
        </div>


    )
        ;

}

export default Cekilis;