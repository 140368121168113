import React, {useEffect, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom'
import {AuthState} from '../../context/auth-context';
import jwt_decode from 'jwt-decode'
import Login from '../../page/authentication/login';

const PrivateRoute = () => {
    const bool = localStorage.getItem("jwtToken") ? true : false
    const [auth, setAuth] = useState(bool)
    const navigate = useNavigate()
    const {setActiveUser} = AuthState()

    const controlAuth = async () => {

        try {

            const userInfo = jwt_decode(localStorage.getItem("jwtToken"));
            setAuth(true)
            setActiveUser(userInfo)

        } catch (error) {

            localStorage.removeItem("jwtToken");

            setAuth(false)
            setActiveUser({})

            navigate("/")
        }
    };
    useEffect(() => {
        controlAuth()
    }, [bool, navigate])


    return (auth ? <Outlet/> : <Login/>)
}

export default PrivateRoute;
