import {Container, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

const Footer = () => {
    const {t} = useTranslation();


    return (
        <div>
            <Container
                sx={{
                    backgroundColor: 'white',
                    position: 'fixed',
                    bottom: '0',
                    left: '50%',
                }}
            >
                <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                >

                     <Typography
                    color="textSecondary"
                    variant="caption"
                  >
                      &copy; 2024 dudullu.com.
                  </Typography>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                    >
                        {(' Sürüm:   ddll 1.0.0')}
                    </Typography>
                </Stack>
            </Container>
        </div>
    )
};

export default Footer;