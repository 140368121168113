import React, {useContext, useEffect, useState} from "react";
import jwt_decode from 'jwt-decode'

export const AuthContext = React.createContext();

const AuthContextProvider = props => {
    const [activeUser, setActiveUser] = useState({})

    const controlAuth = async () => {

        try {
            const userInfo = jwt_decode(localStorage.getItem("jwtToken"));
            console.log(" ACTİVE USER   : ", userInfo)
            setActiveUser(userInfo)
        } catch (error) {

            localStorage.removeItem("jwtToken");

            setActiveUser({})
        }
    };

    useEffect(() => {
        controlAuth()

    }, [])


    return (
        <AuthContext.Provider value={{activeUser, setActiveUser}}>
            {props.children}
        </AuthContext.Provider>
    );
};


export const AuthState = () => {
    return useContext(AuthContext);
};

export default AuthContextProvider;
