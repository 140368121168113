import React, {useState} from 'react'
import {Box, Button, Checkbox, Container, FormControlLabel, Grid, Link, TextField, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import UserService from '../../service/api/user-service'
import {useSnackbar} from "notistack";

const LoginForm = () => {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    const {enqueueSnackbar} = useSnackbar();

    const loginHandler = async () => {
        try {
            const {data} = await UserService.login({username, password})
            localStorage.setItem("jwtToken", data.token);
            console.log(data)
            setTimeout(() => {

                navigate("/dashboard")

            }, 1800)

        } catch (error) {
            enqueueSnackbar("username or password is not correct!", {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: {vertical: "bottom", horizontal: "right"}
            });
        }

    }
    return (
        <Container component="main" maxWidth="xs">
            {/*<Box
                sx={{
                    marginTop: 5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h2" variant="h5">
                    Real Time Location System
                </Typography>
            </Box>*/}

            <Box
                sx={{
                    marginTop: 18,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >


               {/* <Typography component="h1" variant="h5">
                    Sign in
                </Typography>*/}


                <Box noValidate sx={{mt: 1}}>

                    <TextField
                        color='primary'
                        required
                        fullWidth
                        label="Kullanıcı adı"
                        name="username"
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                loginHandler()
                            }
                        }}

                    />
                    <TextField
                        color='primary'
                        fullWidth
                        label="Şifre"
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                loginHandler()
                            }
                        }}
                        sx={{mt: 2}}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary"/>}
                        label="Beni hatırla"
                    />
                    <Button
                        onClick={loginHandler}
                        color="error"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                loginHandler()
                            }
                        }}
                    >
                        GİRİŞ
                    </Button>
                    {/*<Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>*/}
                </Box>
            </Box>
        </Container>

    )
}

export default LoginForm