import {useEffect} from "react";

const Home = () => {
    useEffect(() => {
        window.location.href = 'https://dudullu.com';
    }, []);

    return(
        <div className="flex justify-center min-h-screen w-screen flex-col bg-gray-50">

            <img src={"./dudullu-coming-soon.png"} className="w-full h-auto "/>

        </div>
    );
}
export default Home;