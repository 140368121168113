import React from 'react'
import {Box} from '@mui/material'
import LoginForm from './login-form'


const Login = () => {


    return (
        <Box>
            <LoginForm/>
        </Box>
    )
}


export default Login
