import axios from "axios";
import swal from 'sweetalert';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL + "/auth",
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        // new added
        "Cache-Control": "no-cache"
    }
});

axiosInstance.interceptors.request.use(
    config => {
        config.headers['Authorization'] = `${localStorage.getItem('jwtToken')}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
axiosInstance.interceptors.response.use((response) => {

    return response;

}, (error) => { //In case of an invalid jwt submission.
    if (error.response && error.response.data) {
        //alert(JSON.stringify(error.response.headers));
        if ((401 === error.response.status && 1 === error.response.headers['jwt'])) {
            swal({
                title: "You need to log in again",
                text: "Your session is invalid. Press the OK button to re-enter",
                icon: "warning",
                button: "Okay",
                closeModal: false
            })
                .then((relogin) => {
                    if (relogin) {
                        window.location.href = '/login';
                    } else {
                        swal("Your imaginary file is safe!");
                    }
                });
        } else {
            return Promise.reject(error);
        }
    }
    return Promise.reject(error.message);
});

export default axiosInstance;
