import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            'Language changed': 'Language changed',
            'Users': 'Users',
            'List': 'List',
            'Control Panel': 'Control Panel',
            'User&Access Management': 'User&Access Management',
            'Product Update': 'Product Update',
            'Lookup Tables': 'Lookup Tables',
            'Configuration Settings': 'Configuration Settings',
            'Backup And Restore': 'Backup And Restore',
            'Logs': 'Logs',
            'Monitoring': 'Monitoring',
            'Real Time Monitoring': 'Real Time Monitoring',
            'Real Time Location': 'Real Time Location',
            'Reports': 'Reports',
            'General Logs': 'General Logs',
            'Overview': 'Overview',
            'Location History': 'Location History',
            'Heat Map': 'Heat Map',
            'Devices': 'Devices',
            'Beacons': 'Beacons',
            'Gateways': 'Gateways',
            'Bindings': 'Bindings',
            'Play': 'Play',
            'option1': 'option1',
        }
    },

    tr: {
        translation: {
            'Language changed': 'Dil değişti',
            'Users': 'Kullanıcılar',
            'List': 'Liste',
            'Control Panel': 'Kontrol Paneli',
            'User&Access Management': 'Kullanıcı ve Erişim Yönetimi',
            'Product Update': 'Ürün Güncelleme',
            'Lookup Tables': 'Arama Tabloları',
            'Configuration Settings': 'Yapılandırma Ayarları',
            'Backup And Restore': 'Yedekleme ve Geri Yükleme',
            'Logs': 'Günlükler',
            'Monitoring': 'İzleme',
            'Real Time Monitoring': 'Gerçek Zamanlı İzleme',
            'Real Time Location': 'Gerçek Zamanlı Konum',
            'Reports': 'Raporlar',
            'Overview': 'Genel Bakış',
            'Location History': 'Konum Geçmişi',
            'Heat Map': 'Isı Haritası',
            'Devices': 'Cihazlar',
            'Beacons': 'Beaconlar',
            'Gateways': 'Ağ Geçitleri',
            'Bindings': 'Bağlamalar',
            'Play': 'Oynat',
            'option1': 'seçenek1',
        }
    }

};

export const initializeI18n = (lng) => {
    i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources,
            lng,
            fallbackLng: 'en',
            debug: true,
            interpolation: {
                escapeValue: false
            }
        });
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        }
    });
export default i18n;
