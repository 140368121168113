import React, {useContext, useState} from "react";
import UserService from "../service/api/user-service";
import {useSnackbar} from "notistack";


export const UserContext = React.createContext();

const UserContextProvider = props => {

    const [users, setUsers] = useState([])
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState("")

    const handleFiltersApply = async (searchVal) => {
        setLoading(true)
        console.log("searchVal in handleFiltersApply in uer-context ");
        let searchTerm = (searchVal == "") ? "-1" : searchVal;
        UserService.getAllUsers(searchTerm)
            .then(response => {
                setUsers(response.data)
                setLoading(false)
            })
            .catch(error => {
                enqueueSnackbar("Users can not be retrieved. Internal server error. Please try again later.", {
                    variant: "error",
                    autoHideDuration: process.env.REACT_APP_NOTIFICATION_TIMEOUT,
                    anchorOrigin: {vertical: "bottom", horizontal: "right"}
                });
            })
    };

    return (
        <UserContext.Provider value={{
            users,
            setUsers,
            searchValue,
            setSearchValue,
            handleFiltersApply,
            loading
        }}>
            {props.children}
        </UserContext.Provider>
    );
};


export const UserState = () => {
    return useContext(UserContext);
};

export default UserContextProvider;
