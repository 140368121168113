import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import Login from './page/authentication/login'
import PrivateRoute from './component/routing/private-route'
import User from "./page/dashboard/control-panel/user/users";
import Log from "./page/dashboard/control-panel/log/logs-main";
//import OpenAI from './page/dashboard/control-panel/OpenAI/Main'
import {useSettings} from "./context/settings-context";
import {initializeI18n} from "./i18n";
import {useEffect} from "react";
import Home from "./page/home";
import Cekilis from "./page/dashboard/cekilis";


const App = () => {

    const {settings} = useSettings();

    useEffect(() => {
        initializeI18n(settings.language);
    }, [settings]);
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                {/*<Route exact path="/home" element={<Home/>}/>*/}
                <Route exact path="/login" element={<Login/>}/>

                <Route path="/dashboard" element={<PrivateRoute/>}>
                    <Route index element={<Navigate to="/dashboard/cekilis" replace/>}/>
                    <Route path="/dashboard/cekilis" element={<Cekilis/>}/>
                    {/*<Route element={<LayoutsWithDashboard/>}>

                        <Route index element={<Navigate to="/dashboard/users" replace/>}/>
                        <Route path="users" element={<User/>}/>


                    </Route>*/}
                </Route>
                <Route
                    path="*"
                    element={<Navigate to="/" replace/>}
                />
            </Routes>
        </BrowserRouter>
    );
}


export default App
