import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import AuthContextProvider from "./context/auth-context";
import {SettingsProvider} from "./context/settings-context";
import {SnackbarProvider} from "notistack";
import UserContextProvider from "./context/user-context";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <SettingsProvider>
            <AuthContextProvider>
                <SnackbarProvider maxSnack={3}>
                    <UserContextProvider>
                        <DevSupport ComponentPreviews={ComponentPreviews}
                                    useInitialHook={useInitial}
                        >
                            <App/>
                        </DevSupport>
                    </UserContextProvider>
                </SnackbarProvider>
            </AuthContextProvider>
        </SettingsProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
